// ---------------------------------------------------------------------- >
// ~ SEARCH HELP
// ---------------------------------------------------------------------- >
// Hierarchy :
// ~  : High level section. Title is in summary, separate by "arrow" at the begin and the end (except search help and summary).
// ==== : Upper level in a section
// == : Sub level in a section
// = : Lower level in a section

// ---------------------------------------------------------------------- >
// ~ SUMMARY
// ---------------------------------------------------------------------- >
// 1 - MISC

// ---------------------------------------------------------------------- >
// ~ MISC
// ---------------------------------------------------------------------- >

// ==== Define Fonts
$body-font : Arial, 'Tahoma', sans-serif;

// ==== Define Colors
// == Colors by name (based on : http://chir.ag/projects/name-that-color/)
// USE ONLY TO DEFINE COLORS BY FUNCTION, don't use in others vars declaration.


// == Colors by function
// = General

@import "colors";

$app-primary-hover:                             darken($app-primary, 10%) !default;
$app-secondary :                                $app-secondary !default;
$app-secondary_light :                          #1089ff !default;

$gradient-transparent:                          linear-gradient(45deg, $app-primary 0%, rgba($app-secondary, 0.7) 100%);
$background:                                    #F6F7FB !default;

$app-color:                                     #FE5716 !default;
$app-color-light:                               #ff861d !default;
$app-color-dark:                                darken($app-color, 15%) !default;
$app-color-gradient:                            linear-gradient(45deg, $app-color 0%, rgba($app-color-light, 1) 100%);
$app-color-gradient-transparent:                linear-gradient(to bottom, $app-color 0%, rgba($app-color-light, 0.7) 100%);

$app-success :                                  #4F9e30 !default;
$app-success-dark :                             #529C37 !default;
$app-warning :                                  #ff861d !default;
$app-error :                                    #e23737 !default;

$white:                                         #ffffff !default;
$white-grey:                                    #f6f6f6 !default;
$light-grey:                                    #efefef !default;
$medium-grey:                                   #c6c6c6 !default;
$dark-grey:                                     #666666 !default;
$hover-grey:                                    #efefef !default;
$black:                                         #000000 !default;
$disable:                                       #666666 !default;

// = Fonts color
$app-font-color :                              $dark-grey !default;

// ==== FONTS
$app-font-family:                              'EDF2020', sans-serif !default;

// ==== PATHS
$img-path :                                     '/assets/images/' !default;

$jpg-path :                                     '/assets/jpg/' !default;
$png-path :                                     '/assets/png/' !default;
$svg-path :                                     '/assets/svg/' !default;
$gif-path :                                     '/assets/gif/' !default;

$font-main-path:                                '/assets/font/' !default;
// < ----------------------------------------------------------------------
// ~ MISC END
// < ----------------------------------------------------------------------

// ---------------------------------------------------------------------- >
// ~ LAYOUT
// ---------------------------------------------------------------------- >

// ==== Metrics
$min-width-app:                                  0px;
$max-width-app:                                  1600px;
$app-view-header-padding:                        8px 24px;
$app-gutter:                                     32px;
$app-gutter-sm:                                  10px;
$app-tabs-nav:                                   256px;

// ==== Blocs style
$border-radius:                                   5px;
$box-shadow:                                      0 3px 20px rgba(black, 0.1);
$box-shadow_hover:                                0 3px 10px rgba(black, 0.1);
$box-shadow_press:                                0 1px 5px rgba(black, 0.1);
$box-shadow-color:                                0 0 6px 2px rgba($app-color, 0.3);
$box-shadow-grey:                                0 0 6px 2px rgba($medium-grey, 0.3);

// ==== Popup
$popup-title-color:                              #595959;


// ==== Fix height
$header-height:                                   80px;


// ---------------------------------------------------------------------- >
// ~ LAYOUT END
// ---------------------------------------------------------------------- >


// ---------------------------------------------------------------------- >
// ~ IMAGES
// ---------------------------------------------------------------------- >

$app-bg:                                           url('/assets/png/app-bg.png');

// ---------------------------------------------------------------------- >
// ~ END IMAGES
// ---------------------------------------------------------------------- >
