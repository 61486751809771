body {
    font-family: 'EDF2020', sans-serif !important;
    color: $app-primary;
}

home {
    font-family: 'EDF2020', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
    color: $app-primary;
}

h1 {
    font-size: rem-calc(46px);
    font-weight: bold;

    &.primary {
        color: $app-primary;
    }

    &.secondary {
        color: $app-secondary;
    }
}

h2 {
    font-size: rem-calc(38px);
    font-weight: bold;
    color: $app-primary;

    &.secondary {
        color: $app-secondary;
    }
}

h3 {

    font-size: rem-calc(30px);

    &.primary {
        color: $app-primary;
    }

    &.secondary {
        color: $app-secondary;
    }
}

h4 {
    font-weight: bold;
    font-size: rem-calc(26px);
}

h5 {
    font-weight: bold;
    color: $app-primary;
    line-height: rem-calc(24px);
    font-size: rem-calc(20px);
}

h6 {
    color: $app-primary;
    font-size: rem-calc(16px);
    line-height: rem-calc(22px);

    &.black {
        color: $black;
    }

    &.white {
        color: $white;
    }

    &.color {
        color: $app-color;
    }
}

p {
    color: $app-primary;
    font-size: rem-calc(16px);
    line-height: rem-calc(18px);
}

a {
    color: $app-color;
    font-weight: 600;
    font-size: rem-calc(16px);
    text-decoration: underline;

    &:hover {
        color: $app-color;
        opacity: 0.5;
    }

    &:disabled {
        pointer-events: none;
        cursor: not-allowed;
    }
}

// ================ //
// Text Color
// ================ //

.primary-text {
    color: $app-primary !important;
}

.white-text {
    color: white !important;
}

// Skin label font ////////////////////////////////////////////////////////////////////////////////////////

.label {
    font-weight: bold;
    font-size: rem-calc(8px);
}
